/* eslint @typescript-eslint/no-var-requires: "off" */
const images = {
  LOGO: require('assets/images/logo.svg').default,
  HAMBURGER: require('assets/images/hamburger.png').default,
  CLOSE: require('assets/images/close.png').default,
  FB: require('assets/images/facebook.png').default,
  INSTA: require('assets/images/instagram.png').default,
  TWITTER: require('assets/images/twitter.png').default,
  LINKEDIN: require('assets/images/linkedin.png').default,
  CLOSE_ICON: require('assets/images/close.png').default,
  ERROR_ICON: require('assets/images/error.png').default,
  EMPTY_ICON: require('assets/images/empty.png').default,
  BASE: require('assets/images/main-page-base.png').default,
  COIN: require('assets/images/main-page-coin.svg').default,
  DIAMOND_STAR: require('assets/images/diamond-1.svg').default,
  DIAMOND_TRIANGLE: require('assets/images/diamond-2.svg').default,
  DIAMOND_OCTAGON: require('assets/images/diamond-3.svg').default,
  DIAMOND: require('assets/images/diamond-4.svg').default,
  DIAMOND_HEXAGON: require('assets/images/diamond-5.svg').default,
  PAPER: require('assets/images/about-paper.svg').default,
  MANUFACTURING: require('assets/images/manufacturing.png').default,
  BLOCKCHAIN: require('assets/images/blockchain.png').default,
  VAULTS: require('assets/images/vaults.png').default,
  LABS: require('assets/images/labs.png').default,
  BOOK: require('assets/images/book.json').default,
  WALLET: require('assets/images/wallet.png').default,
  MONEY: require('assets/images/money.json').default,
  INVEST: require('assets/images/invest.svg').default,
  RESEARCH: require('assets/images/research.png').default,
  ARROW_DOWN: require('assets/images/arrow-down.png').default,
  WORLD_1: require('assets/images/world1.png').default,
  WORLD_2: require('assets/images/world2.png').default,
  WORLD_3: require('assets/images/world3.png').default,
  WORLD_4: require('assets/images/world4.png').default,
  CURR_ROADMAP: require('assets/images/roadmap-current.svg').default,
  NEXT_ROADMAP: require('assets/images/roadmap.svg').default,
  LINE: require('assets/images/line.svg').default,
  PDF: require('assets/images/pdf.png').default,
}
export default images;