const colors = {
    // Dark Purple used for Headers
    primary: '#83A6C2',
    // Purple used for Sub Headers and Labels (Replaced #2A1467)
    primaryMedium: '#3B5772',
    // Used for button hover (darken 35%)
    primaryMediumDarkened: '#83A6C2',
    // Light Purple used for landing page text
    primaryLight: '#83A6C2',
    // Orange used for selection and buttons 
    primaryOrange: '#3B5772',
    // Used for button hover (darken 35%)
    primaryOrangeDarkened: '#83A6C2',
    // Dark Purple used for Navbar and Footer
    secondaryText: '#2D263B',
    // Light Grey used for text in secondary pages
    secondaryTextLight: '#5F5F5F',
};

export default colors;