import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppContext } from './context/AppContext';
import ScrollToTop from './ScrollToTop/ScrollToTop'

const ViewHome = lazy(() => import('./views/home'));

function App(): JSX.Element {
  const { state } = useContext(AppContext);

  const direction = state.siteLang === 'ar_SA' ? 'rtl' : 'ltr';

  return (
    <Suspense fallback={<div className='loading' />}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route path="/home" render={props => <ViewHome {...props} />} />
              <Route path="/" exact render={props => <ViewHome {...props} />} />
            </Switch>
          </ScrollToTop>
        </Router>
    </Suspense>
  )
}

export default App;
